
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



















































































































$icon-w: 2.5rem;

.form-group {
  position: relative;
  flex-basis: 100%;
  margin-bottom: $spacing;
  cursor: pointer;
}

.form-checkbox {
  @extend %visually-hidden;
}

.form-label {
  position: relative;
  display: block;
  padding-left: $icon-w + $spacing * 0.5;
  color: $c-gray-darker;
  cursor: pointer;
}

.form-label__icon {
  position: absolute;
  top: -5px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: $icon-w;
  height: $icon-w;
  background: $c-white;
  border: 3px solid $c-gray;
  border-radius: 50%;
  transition: background 0.2s, border-color 0.2s;

  .icon {
    width: 1.6rem;
    height: 1.6rem;
    fill: $c-white;
    opacity: 0;
    transition: opacity 0.2s;
  }

  .is-invalid & {
    border-color: $c-red;
  }

  .form-label:hover &,
  .form-checkbox:focus-visible + .form-label & {
    background: $c-main-green;
    border-color: $c-main-green;
  }

  .form-checkbox:checked + .form-label & {
    background: $c-main-green;
    border-color: $c-main-green;

    .icon {
      opacity: 1;
    }
  }
}
