
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































































































































































/* stylelint-disable property-no-vendor-prefix */

.form-group {
  position: relative;
  flex-basis: 100%;

  &.form-small {
    flex: 15rem 1 1;
  }

  @include mq(xs) {
    &.form-big {
      flex: calc(100% - 19rem) 1 1;
    }
  }

  @include mq(s) {
    flex: 1;

    &.form-full {
      flex-basis: 100%;
    }
  }

  @include mq(l) {
    flex-basis: calc(50% - 4rem);

    &.form-small {
      flex-basis: 20%;
    }

    &.form-big {
      flex-basis: calc(80% - 4rem);
    }
  }
}

.form-input {
  @extend %input-nostyle;

  width: 100%;
  padding: 1rem 0;
  color: $c-black;
  font-weight: 600;
  border-bottom: 1px solid $c-gray-medium;
  transition: border-color 0.2s;

  &::-webkit-search-cancel-button {
    opacity: 0;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
  }

  &:hover {
    border-color: $c-black;
  }

  &:focus {
    border-color: $c-black;

    & + .form-label {
      overflow: visible;
      width: auto;
      height: auto;
      clip: none;
    }
  }

  &[aria-invalid] {
    border-color: $c-red;
  }
}

// file input of
.file-list {
  @extend %list-nostyle;

  position: absolute;
  bottom: $spacing * 0.5;
  left: 0;
}
